import React from 'react';
import {Descriptions, Table, Typography} from 'antd'
import {ToIdr, ToPeriod} from '../../Utils/FormatText'
const {Title} = Typography

const ModalContentHeader = (props) => {
    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ref Number',
            dataIndex: 'reff_number',
            key: 'reff_number',
        },
        {
            title: 'Period',
            dataIndex: 'month_period',
            key: 'month_period',
            render: text => <span>{ToPeriod(text)}</span>
        },
      ];
    return (
        <div>
            <Title level={4}>Item</Title>
            <Table bordered columns={columns} dataSource={props.details} pagination={false} size="middle"/>
            <br/>
            <Title level={4}>Header Detail</Title>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="No St">{props.header.st_number}</Descriptions.Item>
                <Descriptions.Item label="Date St">{props.header.st_date}</Descriptions.Item>
                <Descriptions.Item label="Email">{props.header.email}</Descriptions.Item>
                <Descriptions.Item label="Address 1">{props.header.address_1}</Descriptions.Item>
                <Descriptions.Item label="Address 2">{props.header.address_2}</Descriptions.Item>
                <Descriptions.Item label="Sector">{props.header.sector}</Descriptions.Item>
                <Descriptions.Item label="Area">{props.header.area_wide} m<sup>2</sup></Descriptions.Item>
                <Descriptions.Item label="Pay to 1">{props.header.payto_1}</Descriptions.Item>
                <Descriptions.Item label="Pay to 2">{props.header.payto_2}</Descriptions.Item>
                <Descriptions.Item label="Pay to 3">{props.header.payto_3}</Descriptions.Item>
                <Descriptions.Item label="Fax to">{props.header.faxto}</Descriptions.Item>
                <Descriptions.Item label="Follow up">{props.header.followup}</Descriptions.Item>
                <Descriptions.Item label="Phone Hub">{props.header.phone_hub}</Descriptions.Item>
                <Descriptions.Item label="TT 1">{props.header.tt_1}</Descriptions.Item>
                <Descriptions.Item label="TT 2">{props.header.tt_2}</Descriptions.Item>
                <Descriptions.Item label="Unit Code">{props.header.unit_code}</Descriptions.Item>
                <Descriptions.Item label="Period">{props.header.month_period}</Descriptions.Item>
                <Descriptions.Item label="Place">{props.header.place}</Descriptions.Item>
                <Descriptions.Item label="PL Type">{props.header.pl_type}</Descriptions.Item>
                <Descriptions.Item label="Sector">{props.header.sector}</Descriptions.Item>
                <Descriptions.Item label="Due Date">{props.header.due_date}</Descriptions.Item>
                <Descriptions.Item label="Bill Total">{ToIdr(props.header.bill_total)}</Descriptions.Item>
                <Descriptions.Item label="Address 3">{props.header.address_3}</Descriptions.Item>
                <Descriptions.Item label="F Send">{props.header.f_send}</Descriptions.Item>
                <Descriptions.Item label="Cust Number">{props.header.cust_number}</Descriptions.Item>
                <Descriptions.Item label="Created At">{props.header.created_at}</Descriptions.Item>
                <Descriptions.Item label="Created By">{props.header.created_by}</Descriptions.Item>
            </Descriptions>
        </div>
    );
}

export default ModalContentHeader;
