import React from 'react';
import { Breadcrumb } from 'antd';
import {Link} from 'react-router-dom'

const Breadcrumbs = (props) => {
    return (
        <div className="page-breadcrumb">
            <Breadcrumb>
                {
                    props.items.map((el, i)=>(
                        <Breadcrumb.Item key={'breadcrumb_item_'+i}>
                            {
                                el[1] ? <Link to={el[1]}>{el[0]}</Link> : el[0]
                            }
                        </Breadcrumb.Item>
                    ))
                }
            </Breadcrumb>
        </div>
    );
}

export default Breadcrumbs;
