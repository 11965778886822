import React from 'react';
import { Spin } from 'antd';

const Spinner = () => {
    return (
        <div style={{width:'100%', textAlign:'center'}}>
            <Spin size="large"/>
        </div>
    );
}

export default Spinner;
