import React from 'react';
import {Descriptions} from 'antd'
import {ToIdr, ToPeriod} from '../../Utils/FormatText'

const ModalContentDetail = (props) => {
    return (
        <div>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="No St">{props.detailData.st_number}</Descriptions.Item>
                <Descriptions.Item label="Ref Number">{props.detailData.reff_number}</Descriptions.Item>
                <Descriptions.Item label="Period">{ToPeriod(props.detailData.month_period)}</Descriptions.Item>
                <Descriptions.Item label="Total">{ToIdr(props.detailData.bill)}</Descriptions.Item>
                <Descriptions.Item label="Fine">{ToIdr(props.detailData.fine)}</Descriptions.Item>
                <Descriptions.Item label="Due Date">{props.detailData.due_date}</Descriptions.Item>
                <Descriptions.Item label="Unit Code">{props.detailData.unit_code}</Descriptions.Item>
                <Descriptions.Item label="Description">{props.detailData.description}</Descriptions.Item>
                <Descriptions.Item label="PPN">{props.detailData.ppn}</Descriptions.Item>
            </Descriptions>
        </div>
    );
}

export default ModalContentDetail;
