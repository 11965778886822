import React from 'react';
import parse from 'html-react-parser'

const ModalContentDetail = (props) => {
    return (
        <div>
            {parse(props.modalDetail)}
        </div>
    );
}

export default ModalContentDetail;
