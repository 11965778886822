import React, { useState, useEffect } from 'react';
import { Table, Card, Typography,  Button, Modal } from 'antd';
import {useParams} from 'react-router-dom'
import {ToIdr, ToPeriod} from '../../Utils/FormatText'
import Spinner from '../../Components/Spinner'
import API from '../../Utils/API'
import {ShowDetailButton} from '../../Components/Buttons'
import ModalContentDetail from '../../Components/Modal/ModalContentDetail'
import Breadcrumbs from '../../Components/Breadcrumbs'

const {Title} = Typography

const EmailDetails = () => {
    const {id} = useParams();
    const [showModal, setShowModal] = useState(false)
    const [detailData, setDetailData] = useState(null)
    const [details, setDetails] = useState()

    const getDetails = (batchId) => {
      API.get(`emaildetail/list/${batchId}`).then((res)=>{
        setDetails(res.data.data)
      })
    }

    useEffect(() => {
      getDetails(id)
    }, [id]);

    const columns = [
        {
            title: 'No ST',
            dataIndex: 'st_number',
            key: 'st_number',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Period',
            dataIndex: 'month_period',
            key: 'month_period',
            render: text => <span>{ToPeriod(text)}</span>
        },
        {
            title: 'Total',
            dataIndex: 'bill',
            key: 'bill',
            render: text => <span>{ToIdr(text)}</span>
        },
        {
            title: 'Fine',
            dataIndex: 'fine',
            render: text => <span>{ToIdr(text)}</span>
        },
        {
            title: 'PPN',
            dataIndex: 'ppn',
            render: text => <span>{ToIdr(text)}</span>
        },
        {
          title: 'Action',
          render: (text, record) => 
            <div className="full-width text-center">
            <ShowDetailButton  onShow={() => showDetail(record.key)}/>
          </div>,
        },
      ];
    let pageContent=<Spinner/>;
    if(details){
      pageContent = <Table bordered columns={columns} dataSource={details} size="small"/>
    }

    function showDetail(v){
      setShowModal(true)
      API.get(`emaildetail/detail/${v}`).then((res)=>{
        setDetailData(res.data.data)
      })
    }
    const bc=[['batch','/batch'],['detail',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Email Details</Title>
                {pageContent}
            </Card>
            <Modal
              visible={showModal}
              title="Detail Data"
              onCancel={()=>setShowModal(false)}
              footer={[
                <Button key="back" onClick={()=>setShowModal(false)}>
                  Close
                </Button>,
              ]}
            >
              {
                detailData ? <ModalContentDetail detailData={detailData}/> : <Spinner/>
              }
            </Modal>
        </div>
    );
}

export default EmailDetails;
