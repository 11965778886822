import React, { useState, useEffect } from 'react';
import { Table, Card, Typography, Button, Modal,Space, message, Tag, Row, Col, Select, Tooltip } from 'antd';
import {FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useParams} from 'react-router-dom'
import API from '../../Utils/API'
import {FormatDateTime} from '../../Utils/FormatText'
import Spinner from '../../Components/Spinner'
import ModalContentHeader from '../../Components/Modal/ModalContentHeader'
import {SendButton, ShowDetailButton} from '../../Components/Buttons'
import Breadcrumbs from '../../Components/Breadcrumbs'

const {Title} = Typography
const {Option} = Select

function useForceUpdate(){
  /* eslint-disable */
  const [value, setValue] = useState(0);
  /* eslint-enable */
  return () => setValue(value => ++value);
}

const EmailHeaders = () => {
    const forceUpdate = useForceUpdate()
    const [showModal, setShowModal] = useState(false)
    const [headerDetail, setHeaderDetail] = useState(null)
    const {id} = useParams();
    const [headers, setHeaders] = useState()
    const [headersData, setHeadersData] = useState()

    const getHeaders = (batchId) => {
      API.get(`emailheader/list/${batchId}`).then((res)=>{
        setHeaders(res.data.data)
        setHeadersData(res.data.data)
      })
    }

    useEffect(() => {
      getHeaders(id)
    }, [id]);

    const columns = [
        {
          title: 'No ST',
          dataIndex: 'st_number',
          key: 'st_number',
          render: (text) => getStNumber(text)
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Status',
          dataIndex:'status',
          render: (text, record) => statusTag(text, record)
      
        },
        {
          title: 'Attachment',
          dataIndex: 'attachment',
          key: 'attachment',
          render: (text, record) => attachmentTag(record)
        },
        {
          title: 'Action',
          render: (text, record) => actionButtons(record),
        },
    ];

    const getStNumber = (v) =>{
      return v.substr(0,7)
    }

    const actionButtons = (record) => {
      let sendButton=null;
      if(record.status==='QUEUED' && record.attachment){
        sendButton=<SendButton onSend={()=>sendEmailSingle(record.key)}/>
      }
      return(
        <Space>
          {sendButton}
          <ShowDetailButton  onShow={() => showDetail(record.key)}/>
        </Space>
      )
    }

    const statusTag = (status, record) => {
      if(status==='QUEUED'){
        return (
          <Tooltip title={`Queued on ${FormatDateTime(record.queued_at)}`}>
            <div className="text-center"><Tag color="blue" style={{width:'100px'}}><FieldTimeOutlined/> QUEUED</Tag></div>
          </Tooltip>
        )
      }else if(status==='SENT'){
        return (
          <Tooltip title={`Sent on ${FormatDateTime(record.sent_at)}`}>
            <div className="text-center"><Tag color="green" style={{width:'100px'}}> <CheckCircleOutlined /> SENT</Tag></div>
          </Tooltip>
        )
      }else if(status==='BOUNCED'){
        return (
          <Tooltip title={`Bounced on ${FormatDateTime(record.bounced_at)}`}>
            <div className="text-center"><Tag color="red" style={{width:'100px'}}> <CloseCircleOutlined/> BOUNCED</Tag></div>
          </Tooltip>
        )
      }else{
        return (
          <div className="text-center"><Tag color="default" style={{width:'100px'}}> <ExclamationCircleOutlined /> WAITING </Tag></div>
        )
      }
    }

    function showAttachment(val){
      window.open(`${process.env.REACT_APP_API_URL+val}`, "_blank")
    }

    function generateAttachment(val){
      message.loading('Generating Attachment')
      API.get(`emailattachment/generate/${val}`).then((res)=>{
        let newHeaders=headers
        let index= headers.findIndex(({key}) => key === val)
        newHeaders[index].attachment=res.data.data
        setHeaders(newHeaders)
        forceUpdate()
      }).finally(
        setTimeout(function(){
          message.destroy()
          message.success('Attachment Generated', 1.5)
        }, 500)
      )
    }

    function attachmentTag(val){
      if(val.attachment){
        return (
          <div className="text-center"> 
            <Tag color="blue" style={{cursor:'pointer', width:'120px'}} onClick={() => showAttachment(val.attachment)}>show attachment</Tag>
          </div>
        )
      }else{
        return (
          <div className="text-center"> 
            <Tag color="red" style={{cursor:'pointer', width:'120px'}} onClick={() => generateAttachment(val.key)}>Not Generated</Tag>
          </div>
        )
      }
    }

    function sendEmailSingle(header_id){
      message.loading('sending email', 0)
      API.get(`email/sendbillingdirect/${header_id}`).then((res)=>{
        message.destroy()
        message.success('email sent', 2);
        let newHeaders=headers
        let index= headers.findIndex(({key}) => key === header_id)
        newHeaders[index].status='SENT'
        newHeaders[index].sent_at=res.data.data
        setHeaders((newHeaders))
        forceUpdate()
      })
    }

    function showDetail(v){
      setShowModal(true)
      API.get(`emailheader/detail/${v}/${id}`).then((res)=>{
        setHeaderDetail(res.data.data)
      })
    }

    let pageContent=<Spinner/>;
    if(headers){
      pageContent = <Table bordered columns={columns} dataSource={headers} size="small"/>
    }

    const changeFilter = v =>{
      if(v==='ALL'){
        setHeaders(headersData)
        return forceUpdate()
      }else{
        let newHeaders = headersData.filter(function(h){
          return h.status===v
        })
        setHeaders(newHeaders)
        forceUpdate()
      }
    }
    const bc=[['batch','/batch'],['header',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Row>
                  <Col span={20}>
                    <Title level={3}>Email Headers</Title>
                  </Col>
                  <Col span={4}>
                    <Select defaultValue="ALL" style={{width:'100%'}} onChange={changeFilter}>
                      <Option value="ALL">ALL</Option>
                      <Option value="QUEUED">QUEUED</Option>
                      <Option value="SENT">SENT</Option>
                      <Option value="BOUNCED">BOUNCED</Option>
                    </Select>
                  </Col>
                </Row>
                {pageContent}
            </Card>
            <Modal
              visible={showModal}
              width={750}
              title="Header Detail"
              onCancel={()=>setShowModal(false)}
              footer={[
                <Button key="back" onClick={()=>setShowModal(false)}>
                  Close
                </Button>,
              ]}
            >
              {
                headerDetail ? <ModalContentHeader header={headerDetail.header} details={headerDetail.details}/> : <Spinner/>
              }
            </Modal>
        </div>
    );
}

export default EmailHeaders;
