import React, { useState, useEffect } from 'react';
import { Table, Card, Typography, Space,  message, Row, Col, Tag } from 'antd';
import {FieldTimeOutlined, CheckCircleOutlined} from '@ant-design/icons';
import API from '../../Utils/API'
import { formatDate} from '../../Utils/FormatText'
import Breadcrumbs from '../../Components/Breadcrumbs'
import Spinner from '../../Components/Spinner'
import {SendButton, GenerateButton, EventRecipientButton, CreateButton} from '../../Components/Buttons'
const {Title} = Typography

function useForceUpdate(){
  /* eslint-disable */
  const [value, setValue] = useState(0);
  /* eslint-enable */
  return () => setValue(value => ++value);
}


const BatchList = () => {
  const forceUpdate = useForceUpdate()
    const [batch, setBatch] = useState()
    const loadBatch = () => {
      API.get('emailbatch').then((res)=>{
        setBatch(res.data.data)
      })
    }

    useEffect(() => {
      if(!localStorage.getItem('usertoken')){
        window.location.href='/login'
      }
      loadBatch()
    }, []);

    const columns = [
        {
          title: 'Type',
          dataIndex: 'type',
          render: (text) => typeTag(text)
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'subject',
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          render: (text) => formatDate(text)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (text) => statusTag(text)
        },
        {
          title: 'Detail',
          width:'320px',
          render: (text, record) => detailTag(record)
        },
        {
          title: 'Action',
          width:'15%',
          render: (text, record) => actions(record),
        },
      ];

      const detailTag= (record) => {
        return(
          <div className="text-center">
            <Tag color="blue" >{record.queued} QUEUED</Tag>
            <Tag color="green" >{record.sent} SENT</Tag>
            <Tag color="red" >{record.bounced} BOUNCED</Tag>
          </div>
        )
      }
      const statusTag = (status) => {
        if(status==='UNSENT'){
          return <div className="text-center"><Tag color="blue" style={{width:'80px'}}><FieldTimeOutlined/> UNSENT</Tag></div>
        }else{
          return <div className="text-center"><Tag color="green" style={{width:'80px'}}> <CheckCircleOutlined /> SENT</Tag></div>
        }
      }

      const typeTag = (type) => {
        if(type==='BILLING'){
          return <div className="text-center"><Tag color="gold" style={{width:'80px'}}>BILLING</Tag></div>
        }else{
          return <div className="text-center"><Tag color="volcano" style={{width:'80px'}}>EVENT</Tag></div>
        }
      }

      const actions = (v) =>{
        if(v.type==='BILLING'){
          
          let generateButton=null
          if(v.attachment === '0'){
            generateButton = <GenerateButton onGenerate={()=>generateAttachment(v.key)}/>
          }

          let sendButton=null
          if(v.status === 'UNSENT' && v.attachment === '1'){
            sendButton = <SendButton onSend={() => sendBillingEmail(v.key)}/>
          }
          return (
            <Space className="text-center">
              <EventRecipientButton to={`/batch/billing/${v.key}/headers`}/>
              {generateButton}
              {sendButton}
            </Space>
          )
        }else{
          let sendButton=null
          if(v.status === 'UNSENT'){
            sendButton = <SendButton onSend={() => sendEventEmail(v.key)}/>
          }
          return (
            <Space>
              <EventRecipientButton to={`/batch/event/${v.key}/recipient`}/>
              {sendButton}
            </Space>
          )
        }
      }

      const sendEventEmail = (id) =>{
        message.loading('sending email',0);
        let newBatch=batch
        let index= batch.findIndex(({key}) => key === id)
        newBatch[index].status='SENT'
        setBatch(newBatch)
        API.get(`email/sendevent/${id}`).then((res)=>{
          if(res.status){
            message.destroy()
            message.success('Event Email Queued', 2)
            let newBatch=batch
            let index= batch.findIndex(({key}) => key === id)
            newBatch[index].status='SENT'
            setBatch((newBatch))
            forceUpdate()
          }else{
            message.destroy()
          }
        })
      }

      const sendBillingEmail =async  (id) =>{
        message.loading('sending email');
        API.get(`email/sendbilling/${id}`).then((res)=>{
          if(res.status){
            message.destroy()
            message.success('Billing Notification Queued', 2)
            let newBatch=batch
            let index= batch.findIndex(({key}) => key === id)
            newBatch[index].status='SENT'
            setBatch((newBatch))
            forceUpdate()
          }else{
            message.destroy()
          }
        })
      }

      const generateAttachment=(id)=>{
        message.loading('generating attachment',0);
        API.get(`emailbatch/getHeaderId/${id}`).then((res)=>{
          res.data.data.map((el)=>{
            API.get(`emailattachment/generate/${el.id}`);
            return true;
          })
        }).finally(
          API.get(`emailbatch/updategeneratestatus/${id}`).then((res)=>{
              setTimeout(() => {
                message.destroy()
                message.success('all attachment generated', 2)
                let newBatch=batch
                let index= batch.findIndex(({key}) => key === id)
                newBatch[index].attachment='1'
                setBatch((newBatch))
                forceUpdate()
              }, 5000)
          })
        )
      }
      const bc=[['batch',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}> 
                <Row>
                  <Col span={20}>
                    <Title level={3} style={{float:'left'}}>Email List</Title>
                  </Col>
                  <Col span={4} className="text-right">
                    <CreateButton to="/batch/create">New Batch</CreateButton>
                  </Col>
                </Row>
                {batch ? <Table bordered columns={columns} dataSource={batch} /> : <Spinner/> }
            </Card>
        </div>
    );
}

export default BatchList;
