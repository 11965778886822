import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import {
    ProfileOutlined,
    PieChartOutlined,
    PicCenterOutlined
} from '@ant-design/icons';

const Menus = () => {
    let location = useLocation()
    let defaultSelectedMenu=''
    if(location.pathname.includes('batch')){
        defaultSelectedMenu='batch'
    }else if(location.pathname==='/'){
        defaultSelectedMenu='dashboard'
    }else if(location.pathname.includes('templates')){
        defaultSelectedMenu='template'
    }
    return (
        <Menu theme="light" defaultSelectedKeys={[defaultSelectedMenu]} mode="inline">
            <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
                <Link to="/">
                    Dashboard
                </Link>
            </Menu.Item>
            <Menu.Item key="batch" icon={<ProfileOutlined />}>
                <Link to="/batch">
                    Batch 
                </Link>
            </Menu.Item>
            <Menu.Item key="template" icon={<PicCenterOutlined />}>
                <Link to="/templates">
                    Templates
                </Link>
            </Menu.Item>
        </Menu>
    );
}

export default Menus;
