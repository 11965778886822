import React, { useEffect } from 'react';
import { Table, Card, Typography} from 'antd';
import {DetailButton} from '../../Components/Buttons'
import Breadcrumbs from '../../Components/Breadcrumbs'

const {Title} = Typography

const TemplateList = () => {
  useEffect(()=>{
    if(!localStorage.getItem('usertoken')){
        window.location.href='/login'
    }
  },[])
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
          title: 'Action',
          width:'10%',
          render: (text, record) => showDetail(record)
        },
      ];
      
      const showDetail = v =>{
        if(v.key ==='event'){
          return(
            <div className="full-width text-center" color="primary">
              <DetailButton to={`/templates/${v.key}`}/>
            </div>
          )
        }else{
          return null
        }
      }

      const data = [
          {
            name: 'Event',
            key: 'event'
          },
          {
            name: 'Billing',
            key: 'billing',

          }
      ];
      const bc=[['template',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Template</Title>
                <Table pagination={false} bordered columns={columns} dataSource={data} size="middle"/>
            </Card>
        </div>
    );
}

export default TemplateList;
