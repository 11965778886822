import React from 'react';

const DashboardItem = (props) => {
    return (
        <div style={{width:'100%', backgroundColor:'#fff'}}>
            <div style={{width:'100%', height:60,padding:10, borderBottom:'1px solid #f0f0f0', fontSize:20, fontWeight:500, verticalAlign:'middle'}}>
                {props.title}
            </div>
            <div style={{minHeight:props.height, paddingLeft:20,paddingRight:20,paddingTop:10,verticalAlign:'middle'}}>
                {props.children}
            </div>
        </div>
    );
}

export default DashboardItem;
