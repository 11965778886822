const months=['January','February','March','April','May','June','July','August','September','October','November','December'];

export function ToIdr(val){
    var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });
    
    let v = formatter.format(val);
    return v.substr(0,v.length-3)
}

export function ToPeriod(val){
    return months[parseInt(val.substr(4,2))-1]+' '+val.substr(0,4)
}

export function toDate(val){
    return val.substr(0,10);
}

export function formatDate(val){
    let x=val.substr(0,10);
    let day=x.substr(8,2)
    let month = months[parseInt(x.substr(5,2)-1)]
    let year= x.substr(0,4)
    return day+' '+month+' '+year
}

export function FormatDateTime(val){
    let x=val.substr(0,10);
    let day=x.substr(8,2)
    let month = months[parseInt(x.substr(5,2)-1)]
    let year= x.substr(0,4)
    return day+' '+month+' '+year+', '+val.substr(11,5)
}