import React, { useEffect, useState } from 'react';
import { Row, Col,Progress } from 'antd';
import API from '../Utils/API'
import {Bar} from 'react-chartjs-2';
import Spinner from '../Components/Spinner'
import DashboardItem from '../Components/Dashboard/DashboardItem'

const Dashboard = () => {
      const [statusData, setStatusData] = useState({sent:0,total:0,bounced:0,batch:0})
      const [barData, setBarData] = useState([])
      const [barLabels, setBarLabels] = useState([])
      const [loaded, setLoaded] = useState(false)

      const getStatusdata = () => {
        API.get('dashboard').then((res)=>{
            setLoaded(true)
            setStatusData(res.data.data)
            let bars=res.data.data.bar
            let labels=[];
            let sentsets=[]
            let bouncedsets=[]
            
            bars.forEach(el => {
                sentsets.push(el.sent)
                bouncedsets.push(el.bounced)
                labels.push(el.description)
            });
            setBarLabels(labels)
            setBarData([
                {
                    label: "SENT",
                    backgroundColor: "#78e08f",
                    barThickness: 30,
                    barPercentage: 0.5,
                    data: sentsets
                },{
                    label: "BOUNCED",
                    backgroundColor: "#e55039",
                    barThickness: 30,
                    barPercentage: 0.5,
                    data: bouncedsets
                }
            ])
        })
      }

      useEffect(() => {
        if(!localStorage.getItem('usertoken')){
            window.location.href='/login'
        }
        getStatusdata()
      }, []);

    const counterStyle={
      fontSize:'50px',
      color:'#000',
      fontWeight:'bold'
    }
    const dataBar = {
        labels: barLabels,
        datasets:barData
    };
    return (
        <Row gutter={[16, 16]}>
            <Col className="gutter-row" lg={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                <DashboardItem title="Queued Emails" height={70}>
                    {loaded ? <Progress percent={parseInt(statusData.queued/statusData.total*100)} strokeWidth={20} strokeColor="#808080"/> : <Spinner/>}
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                <DashboardItem title="Sent Emails" height={70}>
                    {loaded ? <Progress percent={parseInt(statusData.sent/statusData.total*100)} strokeWidth={20}/> : <Spinner/> }
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                <DashboardItem title="Bounced Emails" height={70}>
                    {loaded ? <Progress percent={parseInt(statusData.bounced/statusData.total*100)} strokeWidth={20} strokeColor="red"/> : <Spinner/> }
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 6}} sm={{span: 12}} xs={{span: 24}}>
                <DashboardItem title="Queued Emails" height={100}>
                    {loaded ? <div>
                        <span style={counterStyle}>{statusData.queued} </span>
                        <span style={{fontSize:'15px'}}>emails</span>
                    </div>  : <Spinner/> }
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 6}} sm={{span: 12}} xs={{span: 24}}>
                <DashboardItem title="Sent Emails" height={100}>
                    {loaded ? <div>
                        <span style={counterStyle}>{statusData.sent} </span>
                        <span style={{fontSize:'15px'}}>emails</span>
                    </div> : <Spinner/> }
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 6}} sm={{span: 12}} xs={{span: 24}}>
                <DashboardItem title="Bounced Emails" height={100}>
                    {loaded ? <div>
                        <span style={counterStyle}>{statusData.bounced} </span>
                        <span style={{fontSize:'15px'}}>email</span>
                    </div> : <Spinner/> }
                </DashboardItem>
            </Col>
            <Col className="gutter-row" lg={{span: 6}} sm={{span: 12}} xs={{span: 24}}>
                <DashboardItem title="Batch Emails" height={100}>
                    {loaded ? <div>
                        <span style={counterStyle}>{statusData.batch} </span>
                        <span style={{fontSize:'15px'}}>batch</span>
                    </div> : <Spinner/>}
                </DashboardItem>
            </Col>
            <Col className="gutter-row" span={24}>
                <DashboardItem title="Last 5 Batch" height={300}>
                {loaded ? <Bar
                    data={dataBar}
                    width={100}
                    height={50}
                    options={{
                        maintainAspectRatio: false
                    }}
                    /> : <Spinner/>}
                </DashboardItem>
            </Col>
        </Row>
    );
}

export default Dashboard;
