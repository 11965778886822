import React from 'react';
import { Button, Tooltip } from 'antd';
import {Link} from 'react-router-dom';
import {ZoomInOutlined, SendOutlined, SyncOutlined, OrderedListOutlined, ProjectOutlined, TeamOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons'

export const DetailButton = (props) => {
    return (
        <Link to={props.to}>
            <Tooltip title="Show Detail">
                <Button type="primary" icon={<ZoomInOutlined />} shape="round" ></Button>
            </Tooltip>
        </Link>
    );
}

export const BillingDetailButton = (props) => {
    return (
        <Link to={props.to}>
            <Tooltip title="Show Detail Items">
                <Button type="primary" icon={<OrderedListOutlined />} shape="round" ></Button>
            </Tooltip>
        </Link>
    );
}

export const BillingHeaderButton = (props) => {
    return (
        <Link to={props.to}>
            <Tooltip title="Show Header Items">
                <Button type="primary" icon={<ProjectOutlined />} shape="round" ></Button>
            </Tooltip>
        </Link>
    );
}

export const EventRecipientButton = (props) => {
    return (
        <Link to={props.to}>
            <Tooltip title="Show Recipients">
                <Button type="primary" icon={<TeamOutlined />} shape="round" ></Button>
            </Tooltip>
        </Link>
    );
}

export const SendButton = (props) => {
    return (
        <Tooltip title="Send Mail">
            <Button type="primary" icon={<SendOutlined />} shape="round" onClick={props.onSend}></Button>
        </Tooltip>
    );
}

export const GenerateButton = (props) => {
    return (
        <Tooltip title="Generate Attachment">
            <Button type="primary" icon={<SyncOutlined />} shape="round" onClick={props.onGenerate}></Button>
        </Tooltip>
    );
}

export const ShowDetailButton = (props) => {
    return (
        <Tooltip title="Show Detail">
            <Button type="primary" icon={<ZoomInOutlined />} shape="round" onClick={props.onShow}></Button>
        </Tooltip>
    );
}

export const EditButton = (props) => {
    return (
        <Link to={props.to}>
            <Tooltip title="Edit">
                <Button type="primary" icon={ <EditOutlined />} shape="round" ></Button>
            </Tooltip>
        </Link>
    );
}

export const CreateButton = (props) => {
    return (
        <Link to={props.to}>
            <Button icon={  <PlusCircleOutlined />} shape="round" >{props.children}</Button>
        </Link>
    );
}
