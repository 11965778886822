import React, { useState, useEffect } from 'react';
import {Form, Input, Card, Button, Typography, message} from 'antd'
import API from '../Utils/API'

const {Title}=Typography

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
};

const LoginPage = () => {
    useEffect(() => {
        if(localStorage.getItem('usertoken')){
            window.location.href='/'
        }
      }, []);

    const [buttonLoading, setButtonLoading] = useState(false)
    
    const onFinish = v => {
        if(!v.username || !v.password){
            message.error('username and password required', 2)
        }else{
            setButtonLoading(true);
            API.post('auth/login', v).then((res)=>{
                console.log(res)
                if(res.data.status){
                    message.success('Successfully authenticated',3);
                    localStorage.setItem('username', res.data.data.username)
                    localStorage.setItem('usertoken', res.data.data.token)
                    setButtonLoading(false)
                    setTimeout(() => {
                        window.location.href='/'
                    }, 1000);
                }else{
                    message.error('Invalid Credential',3);
                    setButtonLoading(false)
                }
            })
        }
      };
    return (
        <div>
            <Card style={{width:'100%',height:'100vh'}}>
            <Title level={3} className="text-center" style={{marginTop:'50px'}}>Login to Email Blaster</Title>
            <Form {...layout}
                onFinish={onFinish}
                >
                <Form.Item label="Username" name="username">
                    <Input />
                </Form.Item>

                <Form.Item label="Password" name="password">
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={buttonLoading}>
                        Login
                    </Button>
                </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default LoginPage;
