import React, { useState } from 'react';
import {Typography,Form, Input, Button, Card, Upload, message} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import {Editor} from '@tinymce/tinymce-react'
import API from '../../Utils/API'
import Breadcrumbs from '../../Components/Breadcrumbs'
import parse from 'html-react-parser'

const {Title} = Typography
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 3, span: 20 },
};

const BillingTemplate = () => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [headerImage, setHeaderImage] = useState(null)
    const [content, setContent] = useState('')
    function submitForm(v){
        setButtonLoading(true)
        let x={
            description: v.description,
            headerimage: headerImage,
            content: content
        }
        console.log(x)
    }
    const uploadHeaderImage = {
        name: 'file',
        multiple: false,
        accept: '.jpg,.jpeg,.png',
        action: 'https://mailer.sentralapps.com/emailheader/uploadheaderimage',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setHeaderImage(info.file.response.data);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onRemove(info){
          API.get(`emailheader/removefile/${info.response.data}`)
        }
    };
    const setEditorContent = (content)=>{
        setContent(content)
    }
    const bc=[['template','/templates'],['billing',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Billing Email Template</Title>
                <Form onFinish={submitForm}>
                    <Form.Item {...layout} label="Description" name="description">
                      <Input/>
                    </Form.Item>
                    <Form.Item {...layout} label="Document Header">
                        <Upload {...uploadHeaderImage}>
                            <Button>
                                <UploadOutlined />Upload header
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout} name="content">
                        <Editor
                            initialValue=""
                            apiKey='pvgzx8bx2tfljus02ohvuqlu42idy1z1hgmvu7rfwsecjpay'
                            init={{
                                max_height: 700,
                                menubar: false,
                                autoresize_on_init: true,
                                plugins: [
                                    'autolink lists fullscreen media table autoresize',
                                ],
                                toolbar:
                                    'fullscreen undo redo | formatselect | table | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                            }}
                            onEditorChange={setEditorContent}
                        />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>
                            Save template
                        </Button>
                    </Form.Item>
                </Form>
                <br/>
                <Title level={4}>Live Preview</Title>
                <div className="preview-area" >

                    {parse(content)}
                </div>
            </Card>
        </div>
    );
}

export default BillingTemplate;
