import React from 'react';
import Dashboard from '../../Views/Dashboard'
import TemplateList from '../../Views/Template/TemplateList'
import BillingTemplate from '../../Views/Template/BillingTemplate'
import EventTemplateCreate from '../../Views/Template/EventTemplateCreate'
import EventTemplateEdit from '../../Views/Template/EventTemplateEdit'
import EventTemplateList from '../../Views/Template/EventTemplateList'
import EventRecipient from '../../Views/Batch/Event/Recipient'
import BatchList from '../../Views/Batch/BatchList'
import EmailHeaders from '../../Views/Batch/EmailHeaders'
import EmailDetails from '../../Views/Batch/EmailDetails'
import CreateBatch from '../../Views/Batch/CreateBatch'
import LoginPage from'../../Views/LoginPage'
import { Switch, Route } from 'react-router-dom'
import {Layout} from 'antd'

const {Content} = Layout

const AppContent = () => {
    return (
        <Layout className="site-layout site-content">   
            <Content style={{ margin: '10px 16px' }}>
                <Switch>
                    <Route exact path="/">
                        <Dashboard/>
                    </Route>
                    <Route exact path="/batch">
                        <BatchList/>
                    </Route>
                    <Route exact path="/batch/billing/:id/headers">
                        <EmailHeaders/>
                    </Route>
                    <Route exact path="/batch/billing/:id/details">
                        <EmailDetails/>
                    </Route>
                    <Route exact path="/batch/create">
                        <CreateBatch/>
                    </Route>
                    <Route exact path="/templates">
                        <TemplateList/>
                    </Route>
                    <Route exact path="/templates/billing">
                        <BillingTemplate/>
                    </Route>
                    <Route exact path="/templates/event">
                        <EventTemplateList/>
                    </Route>
                    <Route exact path="/templates/event/create">
                        <EventTemplateCreate/>
                    </Route>
                    <Route exact path="/templates/event/:id/edit">
                        <EventTemplateEdit/>
                    </Route>
                    <Route exact path="/batch/event/:id/recipient">
                        <EventRecipient/>
                    </Route>
                    <Route exact path="/login">
                        <LoginPage/>
                    </Route>
                </Switch>
            </Content>
        </Layout>
    );
}

export default AppContent;
