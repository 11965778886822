import React, { useState, useEffect } from 'react';
import {Card,message, Typography,Form, Input, Button, Select  } from 'antd'
import {useHistory} from 'react-router-dom'
import API from '../../Utils/API'
import Breadcrumbs from '../../Components/Breadcrumbs'
const {Title}= Typography
const {Option} = Select

const CreateBatch = () => {
    let history = useHistory();
    const [headerFile, setHeaderFile] = useState(null)
    const [detailFile, setDetailFile] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(false) 
    const [showItem, setShowItem] = useState('none')
    const [targetUrl, setTargetUrl] = useState('savebillingbatch')
    const [isEvent, setIsEvent] = useState(false)
    const [recipientFile, setRecipientFile] = useState(null)
    const [templates, setTemplates] = useState([])

    const getTemplates = () => {
      API.get('emailtemplate/eventlist').then((res)=>{
        setTemplates(res.data.data)
      })
    }

    useEffect(() => {
      getTemplates()
    }, []);

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 8 },
    };

      const submitForm=val=>{
          setButtonLoading(true)
          let x={
              description: val.description,
              headerfile: headerFile,
              detailfile: detailFile,
              type: val.type,
              template: val.template,
              period:val.year+''+val.month,
              recipientfile: recipientFile
          }
          
          if(x.type==='EVENT'){
            if(!x.description || !x.template || !x.recipientfile){
              message.error('All field is required', 2);
              setButtonLoading(false)
            }else{
              const data= new FormData()
              data.append('file', recipientFile)
              data.append('template', x.template)
              data.append('description', x.description)
              data.append('type','EVENT')
              API.post(`emailbatch/${targetUrl}`, data).then((res)=>{
                console.log(res.data)
                if(res.data.status){
                  message.destroy()
                  message.success('data successfully saved',1);
                  history.push('/batch')
                }else{
                  message.destroy()
                  message.error(res.data.message,3);
                  setButtonLoading(false)
                }
              })
            }
          }else if(x.type==='BILLING'){
            if(!x.description || !x.period || !x.headerfile || !x.detailfile){
              message.error('All field is required', 2);
              setButtonLoading(false)
            }else{
              const data= new FormData()
              data.append('headerfile', x.headerfile)
              data.append('detailfile', x.detailfile)
              data.append('description', x.description)
              data.append('type', 'BILLING')
              data.append('period', x.period)
              API.post(`emailbatch/${targetUrl}`, data).then((res)=>{
                if(res.data.status){
                  message.destroy()
                  message.success('data successfully saved',1);
                  setTimeout(function(){
                    history.push('/batch')
                  }, 500)
                }else{
                  message.destroy()
                  message.error(res.data.message,3);
                  setButtonLoading(false)
                }
              })
            }
          }
          
      }
      function changeType(val){
        if(val==='BILLING'){
          setShowItem('')
          setTargetUrl('savebillingbatch')
          setIsEvent(false)
        }else{
          setShowItem('none')
          setTargetUrl('saveeventbatch')
          setIsEvent(true)
        }
      }

      const changeHeaderFile = v =>{
        setHeaderFile(v.target.files[0])
      }

      const changeDetailFile = v =>{
          setDetailFile(v.target.files[0])
      }

      const changeEventRecipient = v =>{
          setRecipientFile(v.target.files[0])
      }

    const bc=[['batch','/batch'],['create',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Create New Batch</Title>
                <Form {...layout} onFinish={submitForm}>
                    <Form.Item label="Type" name="type">
                    <Select onChange={changeType}>
                        <Option value="BILLING">Billing</Option>
                        <Option value="EVENT">Event</Option>
                      </Select>
                      
                    </Form.Item>
                    <Form.Item label="Template" name="template" style={{display:isEvent ? '' : 'none'}}>
                      <Select onChange={changeType}>
                        {
                          templates.map(el=>(
                              <Option value={el.key} key={'template_option'+el.key}>{el.name}</Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item label="Year" name="year" style={{display:showItem}}>
                      <Select>
                        <Option value="2020">2020</Option>
                        <Option value="2019">2019</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Month" name="month" style={{display:showItem}}>
                      <Select>
                        <Option value="01">January</Option>
                        <Option value="02">February</Option>
                        <Option value="03">March</Option>
                        <Option value="04">April</Option>
                        <Option value="05">Mei</Option>
                        <Option value="06">June</Option>
                        <Option value="07">July</Option>
                        <Option value="08">Augustus</Option>
                        <Option value="09">September</Option>
                        <Option value="10">October</Option>
                        <Option value="11">Nophember</Option>
                        <Option value="12">December</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Description" name="description" >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Document Header" style={{display:showItem}}>
                        {/* <Upload {...uploadHeader}>
                            <Button>
                                <UploadOutlined /> Click to Upload
                            </Button>
                        </Upload> */}
                        <input type="file" name="file" onChange={changeHeaderFile} accept=".xls,.xlsx" style={{fontSize:'15px', backgroundColor:'#ededed', padding:'2px', borderRadius:'3px', fontFamily:'Arial, Helvetica, sans-serif'}} />
                    </Form.Item>
                    <Form.Item label="Document Detail" style={{display:showItem}}>
                        {/* <Upload {...uploadDetail}>
                            <Button>
                                <UploadOutlined /> Click to Upload
                            </Button>
                        </Upload> */}
                        <input type="file" name="file" onChange={changeDetailFile} accept=".xls,.xlsx" style={{fontSize:'15px', backgroundColor:'#ededed', padding:'2px', borderRadius:'3px', fontFamily:'Arial, Helvetica, sans-serif'}} />
                    </Form.Item>
                    <Form.Item label="Recipients" style={{display:isEvent ? '' : 'none'}}>
                        {/* <Upload {...uploadEventRecipient}>
                            <Button>
                                <UploadOutlined /> Click to Upload
                            </Button>
                        </Upload> */}
                        <input type="file" name="file" onChange={changeEventRecipient} accept=".xls,.xlsx" style={{fontSize:'15px', backgroundColor:'#ededed', padding:'2px', borderRadius:'3px', fontFamily:'Arial, Helvetica, sans-serif'}} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            
            </Card>
        </div>
    );
}

export default CreateBatch;
