import React, { useState, useEffect } from 'react';
import {Typography,Form, Input, Button, Card,  message, DatePicker} from 'antd'
import {Editor} from '@tinymce/tinymce-react'
import API from '../../Utils/API'
import {useParams} from 'react-router-dom'
import Breadcrumbs from '../../Components/Breadcrumbs'
import moment from 'moment'

const {TextArea} = Input;
const {Title} = Typography;
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};

const EventTemplateCreate = () => {
    const {id} = useParams()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [headerImage, setHeaderImage] = useState(null)
    const [content, setContent] = useState('')
    const [eventDate, setEventDate] = useState(null)
    const [template, setTemplate] = useState({id:null, name:null, date:null, location:null, header_image:null, description:null, created_at:null, created_by:null})

    const getTemplate = (templateId) => {
      API.get(`emailtemplate/detail/${templateId}`).then((res)=>{
        setTemplate(res.data.data)
        setHeaderImage(res.data.data.header_image)
        setContent(res.data.data.description)
        setEventDate(moment(res.data.data.date, 'YYYY-MM-DD'))
      })
    }

    useEffect(() => {
        getTemplate(id)
    }, [id]);


    function submitForm(v){
        setButtonLoading(true)
        const data= new FormData()
        data.append('file', headerImage)
        data.append('eventname', v.eventname)
        data.append('content', content)
        data.append('eventdate', eventDate._i)
        data.append('eventlocation', v.eventlocation)
        API.post(`emailtemplate/updateeventtemplate/${id}`, data).then((res)=>{
            message.success('template saved', 1)
            setTimeout(function(){
                window.location.href='/templates/event'
              }, 1000)
        })
    }

    
    const setEditorContent = (content)=>{
        setContent(content)
    }

    const changeEventDate = (v, dateString) =>{
        setEventDate(dateString)
    }

    const changeFile = v =>{
        // console.log(v)
        setHeaderImage(v.target.files[0])
    }

    let pageContent=null;
    if(template.id){
        pageContent = 
        <Form onFinish={submitForm}>
            <Form.Item {...layout} label="Event Name" name="eventname" initialValue={template.name}>
                <Input />
            </Form.Item>
            <Form.Item {...layout} label="Event Location" name="eventlocation" initialValue={template.location}>
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item {...layout} label="Event Date" name="eventdate" initialValue={moment(template.date, 'YYYY-MM-DD')}>
                <DatePicker onChange={changeEventDate}/>
            </Form.Item>
            <Form.Item {...layout} label="Header File">
                <input type="file" name="file" onChange={changeFile} accept="image/*" style={{fontSize:'15px', backgroundColor:'#ededed', padding:'2px', borderRadius:'3px', fontFamily:'Arial, Helvetica, sans-serif'}} />
            </Form.Item>
            <Form.Item {...tailLayout} name="content" label="Event Detail">
                <Editor
                    initialValue={content}
                    apiKey='pvgzx8bx2tfljus02ohvuqlu42idy1z1hgmvu7rfwsecjpay'
                    init={{
                        max_height: 700,
                        menubar: false,
                        autoresize_on_init: true,
                        plugins: [
                            'autolink lists fullscreen media table autoresize',
                        ],
                        toolbar:
                            'fullscreen undo redo | formatselect | table | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                    }}
                    onEditorChange={setEditorContent}
                />
            </Form.Item>
            
            <Form.Item {...tailLayout} label="">
                <Button type="primary" htmlType="submit" loading={buttonLoading}>
                    Save template
                </Button>
            </Form.Item>
        </Form>
    }



    const bc=[['template','/templates'],['event','/templates/event'],['edit',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Event Email Template</Title>
                {pageContent}
                <br/>
                {/* <Title level={4}>Live Preview</Title>
                <div className="preview-area">
                    {parse(content)}
                </div> */}
            </Card>
        </div>
    );
}

export default EventTemplateCreate;
