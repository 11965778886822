import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import Header from './Components/Layout/Header'
import Sider from './Components/Layout/Sider'
import Footer from './Components/Layout/Footer'
import AppContent from './Components/Layout/AppContent'
import ScrollToTop from './Hooks/ScrollToTop'
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import './main.css';


function App() {
  const isLogin=localStorage.getItem('usertoken');
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Layout theme="light">
          <Header/>
          <Layout style={{ minHeight: '100vh' }}>
            {isLogin ? <Sider/> : null}
            <AppContent/>
          </Layout>
            <Footer/>
          <Layout>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
