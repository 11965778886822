import React, { useState } from 'react';
import {Typography,Form, Input, Button, Card, Upload, message, DatePicker} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import {Editor} from '@tinymce/tinymce-react'
import API from '../../Utils/API'
import {useHistory} from 'react-router-dom'
import Breadcrumbs from '../../Components/Breadcrumbs'

const {TextArea} = Input;
const {Title} = Typography;

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};

const EventTemplateCreate = () => {
    let history = useHistory();
    const [buttonLoading, setButtonLoading] = useState(false)
    const [headerImage, setHeaderImage] = useState(null)
    const [content, setContent] = useState('')
    const [eventDate, setEventDate] = useState(null)

    function submitForm(v){
        setButtonLoading(true)
        let x={
            eventname:v.eventname,
            headerimage: headerImage,
            content: content,
            eventdate: eventDate,
            eventlocation: v.eventlocation,
        }
        API.post('emailtemplate/saveeventtemplate', x).then((res)=>{
            message.success('template saved', 1)
            setTimeout(function(){
                history.push('/templates/event')
              }, 1000)
        })
    }

    const uploadHeaderImage = {
        name: 'file',
        multiple: false,
        accept: '.jpg,.jpeg,.png',
        action: `${process.env.REACT_APP_API_URL}filehandler/uploadeventheader`,
        onChange(info) {
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setHeaderImage(info.file.response.data);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onRemove(info){
          API.get(`filehandler/removeeventheader/${info.response.data}`)
        }
    };
    
    const setEditorContent = (content)=>{
        setContent(content)
    }

    const changeEventDate = (v, dateString) =>{
        setEventDate(dateString)
    }
    const bc=[['template','/templates'],['event','/templates/event'],['create',null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
                <Title level={3}>Event Email Template</Title>
                <Form onFinish={submitForm}>
                    <Form.Item {...layout} label="Event Name" name="eventname">
                      <Input />
                    </Form.Item>
                    <Form.Item {...layout} label="Event Location" name="eventlocation">
                      <TextArea rows={3} />
                    </Form.Item>
                    <Form.Item {...layout} label="Event Date" name="eventdate">
                      <DatePicker onChange={changeEventDate}/>
                    </Form.Item>
                    <Form.Item {...layout} label="Email Header">
                        <Upload {...uploadHeaderImage}>
                            <Button>
                                <UploadOutlined />Upload header
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout} name="content" label="Event Detail">
                        <Editor
                            initialValue=""
                            apiKey='pvgzx8bx2tfljus02ohvuqlu42idy1z1hgmvu7rfwsecjpay'
                            init={{
                                max_height: 700,
                                menubar: false,
                                autoresize_on_init: true,
                                plugins: [
                                    'autolink lists fullscreen media table autoresize',
                                ],
                                toolbar:
                                    'fullscreen undo redo | formatselect | table | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                            }}
                            onEditorChange={setEditorContent}
                        />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout} label="">
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>
                            Save template
                        </Button>
                    </Form.Item>
                </Form>
                <br/>
                {/* <Title level={4}>Live Preview</Title>
                <div className="preview-area">
                    {parse(content)}
                </div> */}
            </Card>
        </div>
    );
}

export default EventTemplateCreate;
