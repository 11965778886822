import React, { useState, useEffect } from 'react';
import { Table, Card, Typography,  Space, Row, Col, Modal, Button } from 'antd';
import Breadcrumbs from '../../Components/Breadcrumbs'
import Spinner from '../../Components/Spinner'
import EventDetailModal from '../../Components/Modal/EventDetailModal'
import {ShowDetailButton, CreateButton, EditButton}  from'../../Components/Buttons'
import API from '../../Utils/API'

const {Title} = Typography

const EventTemplateList = () => {
    const [showModal, setShowModal] = useState(false)
    const [load, setLoad] = useState(false)
    const [modalDetail, setModalDetail] = useState(null)
    const [templateList, setTemplateList] = useState(null)

    const getEventTemplate = () =>{
      API.get('emailtemplate/eventlist').then((res)=>{
        setTemplateList(res.data.data)
      })
    }
    useEffect(() => {
      getEventTemplate()
    },[]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
          title: 'Event date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Event Location',
          dataIndex: 'location',
          key: 'location',
        },
        {
          title: 'Action',
          width:'20%',
          render: (text, record) => actions(record),
        },
    ];
      const actions = (v) =>{
        return (
          <Space>
            <ShowDetailButton onShow={() => showPreview(v.key)}/>
            <EditButton to={`/templates/event/${v.key}/edit`}/>
          </Space>
        )
      }

      const showPreview=(v)=>{
        setShowModal(true)
        setLoad(true)

        API.get(`emailtemplate/preview/${v}`).then((res)=>{
          setModalDetail(res.data.data)
          console.log(res.data.data)
        }).finally(
          setTimeout(function(){
            setLoad(false)
          }, 500)
        )
      }
      
      let pageContent=<Spinner/>;
      if(templateList){
        pageContent= <Table bordered columns={columns} dataSource={templateList} />
      }

      const bc=[['template','/templates'],['event', null]];
    return (
        <div>
            <Breadcrumbs items={bc}/>
            <Card style={{width:'100%'}}>
              <Row>
                  <Col span={20}>
                    <Title level={3}>Event Templates</Title>
                  </Col>
                  <Col span={4} className="text-right">
                    <CreateButton to="/templates/event/create">Create New Template</CreateButton>
                  </Col>
                </Row>
                {pageContent}
            </Card>
            <Modal
              width={640}
              visible={showModal}
              title="Header Detail"
              onCancel={()=>setShowModal(false)}
              footer={[
                <Button key="back" onClick={()=>setShowModal(false)}>
                  Close
                </Button>,
              ]}
            >
              {
                load ? <Spinner/>: <EventDetailModal modalDetail={modalDetail}/>
              }
            </Modal>
        </div>
    );
}

export default EventTemplateList;
