import React, { useState } from 'react';
import { Affix, Layout } from 'antd';

import Menus from './Menus'

const {Sider } = Layout;

const Home = () => {
    const [collapsed, setCollapsed] = useState(false)
    const onCollapse = collap => {
        setCollapsed(collap );
      };
    return (
        <React.Fragment>
            <Affix offsetTop={60}>
                <Sider theme="light" style={{ minHeight: '100vh' }} collapsible collapsed={collapsed} onCollapse={() => onCollapse(!collapsed)}>
                    <div className="logo" />
                    <Menus/>
                </Sider>
            </Affix>
        </React.Fragment>
    );
}

export default Home;
